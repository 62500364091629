// src/pages/OrganizationDashboard.jsx

import React, { useEffect, useState } from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  SimpleGrid,
  Button,
  useColorModeValue,
  Center,
  Spinner,
  HStack,
  Divider,
  Icon,
} from "@chakra-ui/react";
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom";
import {
  FaAppStore,
  FaBullhorn,
  FaUserFriends,
  FaUserPlus,
} from "react-icons/fa";
import axios from "axios";
import Cookies from "js-cookie";

function OrganizationDashboard() {
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const [organizationData, setOrganizationData] = useState(null);
  const [loading, setLoading] = useState(true);

  const bgColor = useColorModeValue("white", "gray.800");
  const cardColor = useColorModeValue("gray.50", "gray.700");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const sectionColor = useColorModeValue("gray.600", "gray.300");

  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        const token = Cookies.get("AuthToken");
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER}/v1/organization/${organizationId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setOrganizationData(response.data.organization);
      } catch (error) {
        console.error("Error fetching organization data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrganizationData();
  }, [organizationId]);

  if (loading) {
    return (
      <Center height="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (!organizationData) {
    return (
      <Center height="100vh">
        <Text color="red.500">Organization not found</Text>
      </Center>
    );
  }

  const {
    name,
    status = "Active",
    apps = [],
    campaigns = [],
    users = [],
    isDeveloper,
    isAdvertiser,
  } = organizationData;

  const handleInvite = () => {
    console.log("Invite to organization functionality triggered");
  };

  const handleCreateApp = () => {
    navigate("./apps/create");
  };

  return (
    <Box maxW="1200px" mx="auto" p={6} bg={bgColor} borderRadius="md">
      <VStack spacing={4} align="start" mt={20}>
        <Heading as="h1" size="lg" color={textColor}>
          {name}
        </Heading>
        <Text fontSize="lg" color={sectionColor}>
          Status: {status}
        </Text>
      </VStack>

      <Divider my={6} />

      {/* Apps Section - Show only if organization is a developer */}
      {isDeveloper ? (
        <Box my={8}>
          <HStack spacing={2} mb={4}>
            <Icon as={FaAppStore} boxSize={6} color="teal.400" />
            <Heading as="h2" size="md" color={textColor}>
              Apps ({apps.length})
            </Heading>
          </HStack>
          {apps.length > 0 ? (
            <>
              <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                {apps.slice(0, 4).map((app) => (
                  <Box
                    key={app._id}
                    p={4}
                    bg={cardColor}
                    borderRadius="lg"
                    shadow="md"
                  >
                    <Heading as="h3" size="sm" color={textColor} mb={2}>
                      {app.name}
                    </Heading>
                    <Text color={sectionColor} fontSize="sm">
                      Created on: {new Date(app.createdAt).toLocaleDateString()}
                    </Text>
                    <Button
                      as={RouterLink}
                      to={`./apps/${app._id}`} // Corrected to app._id
                      colorScheme="teal"
                      size="sm"
                      mt={3}
                    >
                      Manage App
                    </Button>
                  </Box>
                ))}
              </SimpleGrid>
              {apps.length > 4 && (
                <Button
                  as={RouterLink}
                  to={`/organization/${organizationId}/apps`}
                  colorScheme="teal"
                  variant="link"
                  mt={4}
                >
                  View All Apps
                </Button>
              )}
            </>
          ) : (
            <Box>
              <Text color={sectionColor} mb={4}>
                No apps found for this organization.
              </Text>
              <Button colorScheme="teal" onClick={handleCreateApp}>
                Create App
              </Button>
            </Box>
          )}
        </Box>
      ) : (
        <Box my={8}>
          <Text fontSize="lg" color={sectionColor} mb={4}>
            Want to create apps? Apply to become a developer.
          </Text>
          <Button colorScheme="teal" variant="solid">
            Apply as Developer
          </Button>
        </Box>
      )}

      <Divider my={6} />

      {/* Campaigns Section - Show only if organization is an advertiser */}
      {isAdvertiser ? (
        <Box my={8}>
          <HStack spacing={2} mb={4}>
            <Icon as={FaBullhorn} boxSize={6} color="teal.400" />
            <Heading as="h2" size="md" color={textColor}>
              Campaigns ({campaigns.length})
            </Heading>
          </HStack>
          {campaigns.length > 0 ? (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
              {campaigns.map((campaign) => (
                <Box
                  key={campaign._id}
                  p={4}
                  bg={cardColor}
                  borderRadius="lg"
                  shadow="md"
                >
                  <Heading as="h3" size="sm" color={textColor} mb={2}>
                    {campaign.title}
                  </Heading>
                  <Text color={sectionColor} fontSize="sm">
                    Status: {campaign.status || "Active"}
                  </Text>
                  <Button
                    as={RouterLink}
                    to={`/campaigns/${campaign._id}`}
                    colorScheme="teal"
                    size="sm"
                    mt={3}
                  >
                    Manage Campaign
                  </Button>
                </Box>
              ))}
            </SimpleGrid>
          ) : (
            <Text color={sectionColor}>
              No campaigns found for this organization.
            </Text>
          )}
        </Box>
      ) : (
        <Box my={8}>
          <Text fontSize="lg" color={sectionColor} mb={4}>
            Want to run campaigns? Apply to become an advertiser.
          </Text>
          <Button colorScheme="teal" variant="solid">
            Apply as Advertiser
          </Button>
        </Box>
      )}

      <Divider my={6} />

      {/* Team Members Section */}
      <Box my={8}>
        <HStack spacing={2} mb={4}>
          <Icon as={FaUserFriends} boxSize={6} color="teal.400" />
          <Heading as="h2" size="md" color={textColor}>
            Team Members ({users.length})
          </Heading>
        </HStack>
        {users.length > 0 ? (
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
            {users.map((user) => (
              <Box
                key={user.userId}
                p={4}
                bg={cardColor}
                borderRadius="lg"
                shadow="md"
              >
                <Heading as="h3" size="sm" color={textColor} mb={2}>
                  {user.name}
                </Heading>
                <Text color={sectionColor} fontSize="sm">
                  Role: {user.role}
                </Text>
              </Box>
            ))}
          </SimpleGrid>
        ) : (
          <Text color={sectionColor}>
            No team members assigned to this organization.
          </Text>
        )}
        <Button
          leftIcon={<FaUserPlus />}
          colorScheme="teal"
          mt={4}
          onClick={handleInvite}
        >
          Invite to Organization
        </Button>
      </Box>
    </Box>
  );
}

export default OrganizationDashboard;
