import React, { useEffect, useState } from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  SimpleGrid,
  useColorModeValue,
  Center,
  Spinner,
  HStack,
  Icon,
  Stack,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { FaBuilding, FaPlus } from "react-icons/fa";
import axios from "axios";
import Cookies from "js-cookie";

function AllOrganizationDashboard() {
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const bgColor = useColorModeValue("white", "gray.800");
  const cardColor = useColorModeValue("gray.50", "gray.700");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const buttonBgColor = useColorModeValue("teal.500", "teal.300");
  const buttonHoverColor = useColorModeValue("teal.600", "teal.400");

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const token = Cookies.get("AuthToken");
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER}/v1/organization/user-organizations`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setOrganizations(response.data.organizations || []);
      } catch (error) {
        console.error("Error fetching organizations:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrganizations();
  }, []);

  const handleCreateOrganization = () => {
    navigate("/organizations/create-organization");
  };

  if (loading) {
    return (
      <Center height="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Box maxW="1200px" mx="auto" p={6} bg={bgColor}>
      <VStack spacing={6} align="center" textAlign="center">
        <Text fontSize="md" color={textColor} maxW="600px" mt={20}>
          Manage your organizations here. View status, details, and manage your
          ads and apps all in one place.
        </Text>

        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6} mt={8}>
          {/* Organization Cards */}
          {organizations.map((organization) => (
            <Box
              key={organization._id}
              p={8}
              bg={cardColor}
              borderRadius="lg"
              shadow="lg"
              borderWidth="1px"
              borderColor="gray.200"
              transition="transform 0.2s"
              _hover={{ transform: "scale(1.03)", shadow: "2xl" }}
              display="flex"
              flexDirection="column"
              alignItems="start"
            >
              <Stack direction="row" align="center" spacing={4}>
                <Icon as={FaBuilding} boxSize={8} color="teal.400" />
                <Heading as="h3" size="md" color={textColor}>
                  {organization.name}
                </Heading>
              </Stack>
              <Text color={textColor} fontSize="sm" mt={2} fontWeight="medium">
                Status:{" "}
                <Text as="span" fontWeight="bold">
                  {organization.status || "Active"}
                </Text>
              </Text>
              <Button
                as={RouterLink}
                to={`/organization/${organization._id}`}
                colorScheme="teal"
                variant="solid"
                width="full"
                mt={8}
                bg={buttonBgColor}
                _hover={{ bg: buttonHoverColor }}
              >
                Manage
              </Button>
            </Box>
          ))}
          {/* Create Organization Box */}
          <Box
            p={6}
            bg={cardColor}
            borderRadius="lg"
            shadow="lg"
            borderWidth="1px"
            borderColor="gray.200"
            transition="transform 0.2s"
            _hover={{ transform: "scale(1.02)" }}
            onClick={handleCreateOrganization}
            cursor="pointer"
          >
            <VStack align="center" spacing={4}>
              <Icon as={FaPlus} boxSize={8} color="teal.400" />
              <Heading as="h3" size="md" color={textColor}>
                Create New Organization
              </Heading>

              <Button
                colorScheme="teal"
                variant="solid"
                bg={buttonBgColor}
                _hover={{ bg: buttonHoverColor }}
                mt={4}
              >
                Create
              </Button>
            </VStack>
          </Box>
        </SimpleGrid>
      </VStack>
    </Box>
  );
}

export default AllOrganizationDashboard;
