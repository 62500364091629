import React, { useEffect, useState } from "react";
import {
  Box,
  VStack,
  Avatar,
  Heading,
  Text,
  Divider,
  HStack,
  Button,
  Input,
  FormControl,
  FormLabel,
  useColorModeValue,
  Center,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

function Profile() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const toast = useToast();
  const navigate = useNavigate();

  const bgColor = useColorModeValue("white", "gray.800");
  const cardColor = useColorModeValue("gray.50", "gray.700");
  const textColor = useColorModeValue("gray.700", "gray.200");

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const token = Cookies.get("AuthToken");
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER}/v1/user/profile`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserData(response.data.user);
        setName(response.data.user.name);
        setEmail(response.data.user.email);
      } catch (error) {
        toast({
          title: "Error loading profile",
          description: "Failed to fetch profile data.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, [toast]);

  const handleDeleteAccount = async () => {
    try {
      setDeleting(true);
      const token = Cookies.get("AuthToken");
      await axios.delete(`${process.env.REACT_APP_SERVER}/v1/user/profile`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      Cookies.remove("AuthToken");
      toast({
        title: "Account deleted",
        description: "Your account has been deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/login");
    } catch (error) {
      toast({
        title: "Error deleting account",
        description: "Failed to delete your account.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setDeleting(false);
    }
  };

  const handleEditProfile = () => {
    setEditing(true);
  };

  const handleSaveChanges = async () => {
    try {
      const token = Cookies.get("AuthToken");
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER}/v1/user/profile`,
        { name, email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setUserData(response.data.user);
      setEditing(false);
      toast({
        title: "Profile updated",
        description: "Your profile has been updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error updating profile",
        description: "Failed to update your profile.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCancelEdit = () => {
    setEditing(false);
    setName(userData.name);
    setEmail(userData.email);
  };

  if (loading) {
    return (
      <Center height="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Box maxW="600px" mx="auto" p={6} bg={bgColor}>
      <Box mt={100}>
        <VStack spacing={6} textAlign="center">
          <Avatar size="2xl" name={userData.name} src={userData.avatarUrl} />
          <Heading as="h1" size="lg" color={textColor}>
            {userData.name}
          </Heading>
          <Text fontSize="lg" color={textColor}>
            {userData.email}
          </Text>
        </VStack>
        <Divider my={6} />
        <Box p={4} bg={cardColor} borderRadius="md" boxShadow="sm">
          <Heading as="h2" size="md" mb={4} color={textColor}>
            Account Details
          </Heading>
          {editing ? (
            <VStack align="start" spacing={3}>
              <FormControl id="name">
                <FormLabel color={textColor}>Name</FormLabel>
                <Input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  color={textColor}
                />
              </FormControl>
              <FormControl id="email">
                <FormLabel color={textColor}>Email</FormLabel>
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  color={textColor}
                />
              </FormControl>
            </VStack>
          ) : (
            <VStack align="start" spacing={3}>
              <HStack justify="space-between" width="100%">
                <Text fontWeight="bold" color={textColor}>
                  Name:
                </Text>
                <Text color={textColor}>{userData.name}</Text>
              </HStack>
              <HStack justify="space-between" width="100%">
                <Text fontWeight="bold" color={textColor}>
                  Email:
                </Text>
                <Text color={textColor}>{userData.email}</Text>
              </HStack>
              <HStack justify="space-between" width="100%">
                <Text fontWeight="bold" color={textColor}>
                  Joined:
                </Text>
                <Text color={textColor}>
                  {new Date(userData.createdAt).toLocaleDateString()}
                </Text>
              </HStack>
            </VStack>
          )}
        </Box>
        <Divider my={6} />
        <VStack spacing={4}>
          {editing ? (
            <>
              <Button
                colorScheme="teal"
                onClick={handleSaveChanges}
                width="full"
              >
                Save Changes
              </Button>
              <Button variant="outline" onClick={handleCancelEdit} width="full">
                Cancel
              </Button>
            </>
          ) : (
            <Button
              colorScheme="teal"
              variant="outline"
              onClick={handleEditProfile}
              width="full"
            >
              Edit Profile
            </Button>
          )}
          <Button
            colorScheme="red"
            variant="solid"
            isLoading={deleting}
            onClick={handleDeleteAccount}
            width="full"
          >
            Delete Account
          </Button>
        </VStack>
      </Box>
    </Box>
  );
}

export default Profile;
