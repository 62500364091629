import React from "react";
import {
  Flex,
  Text,
  Button,
  IconButton,
  useColorModeValue,
  Link,
  Stack,
} from "@chakra-ui/react";
import { FaDiscord, FaGithub, FaTwitter, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  const sectionTextColor = useColorModeValue("gray.600", "gray.300");

  return (
    <Flex
      as="footer"
      direction={{ base: "column", md: "row" }}
      justify={{ base: "center", md: "space-between" }}
      align="center"
      p={4}
      borderTop="1px"
      borderColor={useColorModeValue("gray.200", "gray.600")}
    >
      {/* Company Info */}
      <Text color={sectionTextColor} mb={{ base: 4, md: 0 }}>
        © {new Date().getFullYear()} Nexxy LLC
      </Text>

      {/* Links */}
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={4}
        mb={{ base: 4, md: 0 }}
      >
        <Button as={Link} href="/terms" variant="link" color={sectionTextColor}>
          Terms of Service
        </Button>
        <Button
          as={Link}
          href="/privacy"
          variant="link"
          color={sectionTextColor}
        >
          Privacy Policy
        </Button>
      </Stack>

      {/* Social Icons */}
      <Flex
        justify="center"
        wrap="wrap"
        direction={{ base: "row", md: "row" }}
        ml={{ md: 4 }}
      >
        <IconButton
          as="a"
          href="https://discord.gg/jTxGvcQ3Au"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Discord"
          variant="ghost"
          colorScheme="teal"
          icon={<FaDiscord />}
          mx={1}
          mb={{ base: 2, md: 0 }}
        />
        <IconButton
          as="a"
          href="https://github.com/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="GitHub"
          variant="ghost"
          colorScheme="teal"
          icon={<FaGithub />}
          mx={1}
          mb={{ base: 2, md: 0 }}
        />
        <IconButton
          as="a"
          href="https://twitter.com/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Twitter"
          variant="ghost"
          colorScheme="teal"
          icon={<FaTwitter />}
          mx={1}
          mb={{ base: 2, md: 0 }}
        />
        <IconButton
          as="a"
          href="https://www.linkedin.com/company/104995201"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="LinkedIn"
          variant="ghost"
          colorScheme="teal"
          icon={<FaLinkedin />}
          mx={1}
          mb={{ base: 2, md: 0 }}
        />
      </Flex>
    </Flex>
  );
};

export default Footer;
