import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Flex,
  HStack,
  Link,
  Spacer,
  useColorMode,
  IconButton,
  Button,
  Text,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  VStack,
  Divider,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon, HamburgerIcon } from "@chakra-ui/icons";

function Navbar() {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        as="nav"
        bg={colorMode === "light" ? "white" : "gray.800"}
        borderBottomWidth={1}
        borderBottomColor={colorMode === "light" ? "gray.200" : "gray.700"}
        py={4}
        shadow="md"
        width="100%"
        zIndex={50}
        position="fixed"
      >
        <Flex maxW="1200px" mx="auto" align="center" px={6}>
          {/* Logo */}
          <Text
            as={RouterLink}
            to="/"
            fontWeight="bold"
            fontSize="2xl"
            color={colorMode === "light" ? "gray.700" : "gray.300"}
          >
            Nexxy
          </Text>

          <Spacer />

          {/* Desktop Navigation */}
          <HStack
            spacing={4}
            display={{ base: "none", md: "flex" }}
            color={colorMode === "light" ? "gray.700" : "gray.300"}
          >
            <Link
              as={RouterLink}
              to="/"
              fontWeight="bold"
              _hover={{ color: colorMode === "light" ? "black" : "white" }}
            >
              Learn More
            </Link>
            <Link
              as={RouterLink}
              to="/support"
              fontWeight="bold"
              _hover={{ color: colorMode === "light" ? "black" : "white" }}
            >
              Support
            </Link>
            <IconButton
              onClick={toggleColorMode}
              aria-label="Toggle color mode"
              icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
              variant="ghost"
            />
            <Button
              as={RouterLink}
              to="/login"
              colorScheme="teal"
              variant="solid"
              size="sm"
            >
              Login
            </Button>
            <Button
              as={RouterLink}
              to="/early-access"
              colorScheme="teal"
              variant="outline"
              size="sm"
            >
              Early Access
            </Button>
          </HStack>

          {/* Mobile Menu Button */}
          <IconButton
            display={{ base: "block", md: "none" }}
            icon={<HamburgerIcon />}
            variant="ghost"
            aria-label="Open Menu"
            onClick={onOpen}
            ml={2}
            color={colorMode === "light" ? "gray.700" : "gray.300"}
          />
        </Flex>
      </Box>

      {/* Mobile Drawer Menu */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent bg={colorMode === "light" ? "white" : "gray.800"}>
          <DrawerCloseButton
            color={colorMode === "light" ? "gray.700" : "gray.300"}
          />
          <DrawerBody pt={10}>
            <VStack
              align="start"
              spacing={4}
              color={colorMode === "light" ? "gray.700" : "gray.300"}
            >
              <Link
                as={RouterLink}
                to="/"
                fontWeight="bold"
                onClick={onClose}
                _hover={{ color: colorMode === "light" ? "black" : "white" }}
              >
                Learn More
              </Link>
              <Link
                as={RouterLink}
                to="/support"
                fontWeight="bold"
                onClick={onClose}
                _hover={{ color: colorMode === "light" ? "black" : "white" }}
              >
                Support
              </Link>
              <Divider />
              <IconButton
                onClick={toggleColorMode}
                aria-label="Toggle color mode"
                icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
                variant="ghost"
                colorScheme="teal"
              />
              <Button
                as={RouterLink}
                to="/login"
                colorScheme="teal"
                variant="solid"
                size="md"
                width="full"
                onClick={onClose}
              >
                Login
              </Button>
              <Button
                as={RouterLink}
                to="/early-access"
                colorScheme="teal"
                variant="outline"
                size="md"
                width="full"
                onClick={onClose}
              >
                Early Access
              </Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Navbar;
