import React from "react";
import { Box, Heading, Text, VStack } from "@chakra-ui/react";

const PrivacyPolicy = () => {
  return (
    <Box p={4} maxW="800px" mx="auto" mt={10}>
      <Heading as="h1" size="xl" mb={4} textAlign="center">
        Privacy Policy
      </Heading>
      <VStack spacing={6} align="start">
        <Box>
          <Heading as="h2" size="md" mb={2}>
            1. Introduction
          </Heading>
          <Text>
            This Privacy Policy ("Policy") explains how Nexxy AdNet ("we," "us,"
            or "our") collects, uses, shares, and safeguards your personal
            information when you visit our website, use our platform, or
            otherwise interact with our services (collectively, "Services"). By
            accessing or using our Services, you agree to the collection and use
            of your information as described in this Policy.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            2. Information We Collect
          </Heading>
          <Text>
            We may collect various types of information about you, including:
          </Text>
          <Text as="ul" pl={4}>
            <Text as="li">
              <strong>Personal Information:</strong> Information that can be
              used to identify you, such as your name, email address, billing
              address, and payment details.
            </Text>
            <Text as="li">
              <strong>Account Information:</strong> Information you provide when
              registering an account, including your username and password.
            </Text>
            <Text as="li">
              <strong>Communications:</strong> Records of correspondence with
              us, including support requests and inquiries.
            </Text>
            <Text as="li">
              <strong>Usage Data:</strong> Information about how you use and
              interact with our Services, such as IP addresses, device
              identifiers, browser type, and pages viewed.
            </Text>
            <Text as="li">
              <strong>Cookie Data:</strong> Information collected through
              cookies and similar technologies to improve your user experience
              and analyze traffic patterns.
            </Text>
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            3. How We Use Your Information
          </Heading>
          <Text>
            We use the information we collect for various purposes, including:
          </Text>
          <Text as="ul" pl={4}>
            <Text as="li">
              <strong>Service Provision:</strong> To operate, maintain, and
              improve our Services, including processing payments and delivering
              requested features.
            </Text>
            <Text as="li">
              <strong>Communication:</strong> To respond to inquiries, provide
              customer support, and send you updates, alerts, and notifications.
            </Text>
            <Text as="li">
              <strong>Personalization:</strong> To tailor your experience,
              including showing ads and content based on your preferences and
              usage patterns.
            </Text>
            <Text as="li">
              <strong>Analytics and Improvement:</strong> To understand how
              users interact with our Services, measure performance, and enhance
              user satisfaction.
            </Text>
            <Text as="li">
              <strong>Legal and Compliance:</strong> To comply with applicable
              laws, regulations, and legal processes, and to protect our rights
              and interests.
            </Text>
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            4. How We Share Your Information
          </Heading>
          <Text>
            We may share your information under the following circumstances:
          </Text>
          <Text as="ul" pl={4}>
            <Text as="li">
              <strong>Service Providers:</strong> With trusted third-party
              vendors, such as payment processors (e.g., Stripe) or hosting
              providers, who assist us in delivering and maintaining our
              Services.
            </Text>
            <Text as="li">
              <strong>Business Transfers:</strong> If we are involved in a
              merger, acquisition, reorganization, or sale of assets, your
              information may be transferred as part of that transaction.
            </Text>
            <Text as="li">
              <strong>Legal Requirements:</strong> If required by law,
              regulation, or legal process, or if we believe disclosure is
              necessary to protect our rights, property, or safety.
            </Text>
            <Text as="li">
              <strong>With Your Consent:</strong> In other circumstances, we
              will share your information only if you give us explicit
              permission.
            </Text>
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            5. Data Security and Retention
          </Heading>
          <Text>
            We implement reasonable administrative, technical, and physical
            safeguards to protect your information from unauthorized access,
            disclosure, or misuse. However, no security system is perfect, and
            we cannot guarantee absolute protection.
          </Text>
          <Text mt={2}>
            We retain personal information for as long as necessary to fulfill
            the purposes outlined in this Policy or as required by law. When no
            longer needed, we will securely delete or anonymize your data.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            6. Cookies and Tracking Technologies
          </Heading>
          <Text>
            We use cookies, web beacons, and similar technologies to enhance
            your browsing experience, analyze site traffic, personalize content,
            and deliver targeted advertisements. You can manage or disable
            cookies through your browser settings. By continuing to use our
            Services, you consent to our use of cookies as described in this
            Policy.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            7. Your Choices and Rights
          </Heading>
          <Text>
            Depending on your location, you may have rights regarding your
            personal information, such as the right to access, correct, or
            delete your data. To exercise these rights, please contact us at the
            email address provided below. We will respond in accordance with
            applicable laws and regulations.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            8. Children’s Privacy
          </Heading>
          <Text>
            Our Services are not intended for individuals under the age of 16.
            We do not knowingly collect personal information from children. If
            you believe we have collected information from a child without
            parental consent, please contact us and we will promptly remove the
            information.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            9. International Data Transfers
          </Heading>
          <Text>
            If you are accessing our Services from outside the country in which
            we operate, your information may be transferred to, stored, and
            processed in a different jurisdiction. By using our Services, you
            consent to such transfers.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            10. Changes to This Policy
          </Heading>
          <Text>
            We may update this Policy from time to time to reflect changes in
            our practices or applicable laws. When we do, we will revise the
            "Last Updated" date at the top of this page. If we make significant
            changes, we will notify you by email or by posting a prominent
            notice on our website. Your continued use of our Services after any
            changes indicates your acceptance of the updated Policy.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            11. Contact Us
          </Heading>
          <Text>
            If you have any questions, concerns, or requests regarding this
            Privacy Policy or our data practices, please contact us at:{" "}
            <a href="mailto:support@nexxyapp.com">support@nexxyapp.com</a>.
          </Text>
        </Box>
      </VStack>
    </Box>
  );
};

export default PrivacyPolicy;
