import React from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <Box
      minH="70vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg={useColorModeValue("gray.100", "gray.800")}
      p={6}
    >
      <VStack spacing={6} textAlign="center">
        <Heading as="h1" size="2xl">
          404
        </Heading>
        <Text fontSize="xl" color={useColorModeValue("gray.600", "gray.300")}>
          Oops! The page you're looking for doesn't exist.
        </Text>
        <Text color={useColorModeValue("gray.500", "gray.400")}>
          It might have been removed, or the link might be broken.
        </Text>
      </VStack>
    </Box>
  );
}

export default NotFound;
