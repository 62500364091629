import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack,
  Link,
  Heading,
  useToast,
  useColorMode,
  useColorModeValue,
  IconButton,
} from "@chakra-ui/react";
import { SunIcon, MoonIcon } from "@chakra-ui/icons";
import Footer from "../../template/footer";
function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const toast = useToast();

  const { toggleColorMode } = useColorMode();
  const bg = useColorModeValue("gray.50", "gray.900");
  const cardBg = useColorModeValue("white", "gray.800");
  const inputBg = useColorModeValue("gray.100", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const textColor = useColorModeValue("gray.800", "white");
  const placeholderColor = useColorModeValue("gray.500", "gray.400");

  useEffect(() => {
    const token = Cookies.get("AuthToken");
    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const loginHandler = async () => {
    if (email && password) {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_SERVER}/v1/auth/login`,
          {
            email,
            password,
          }
        );

        if (res.data.accessToken) {
          Cookies.set("AuthToken", res.data.accessToken, { expires: 1 });

          toast({
            title: "Login successful.",
            description: "You have been logged in successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });

          navigate("/dashboard");
        } else {
          toast({
            title: "Login failed.",
            description: res.data.message || "Please try again.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (err) {
        toast({
          title: "Error logging in.",
          description:
            err.response?.data?.message || "An unexpected error occurred.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Missing information.",
        description: "Please fill in all fields.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Box
        minH="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg={bg}
        color={textColor}
      >
        <IconButton
          icon={useColorModeValue(<MoonIcon />, <SunIcon />)}
          onClick={toggleColorMode}
          position="absolute"
          top={4}
          right={4}
          aria-label="Toggle Theme"
        />
        <Box
          w="400px"
          p={6}
          borderRadius="md"
          boxShadow="lg"
          bg={cardBg}
          border="1px solid"
          borderColor={borderColor}
        >
          <Heading as="h1" size="lg" textAlign="center" mb={6}>
            Login
          </Heading>
          <VStack spacing={4} align="stretch">
            <FormControl id="email" isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                bg={inputBg}
                borderColor={borderColor}
                _placeholder={{ color: placeholderColor }}
                _hover={{ borderColor: "teal.400" }}
              />
            </FormControl>

            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                bg={inputBg}
                borderColor={borderColor}
                _placeholder={{ color: placeholderColor }}
                _hover={{ borderColor: "teal.400" }}
              />
            </FormControl>

            <Link as={RouterLink} to="/password-reset" color="teal.400">
              Forgot Password?
            </Link>

            <Button
              colorScheme="teal"
              onClick={loginHandler}
              width="full"
              bg="teal.500"
              _hover={{ bg: "teal.400" }}
            >
              Login
            </Button>

            <Text textAlign="center">
              Don’t have an account?{" "}
              <Link as={RouterLink} to="/signup" color="teal.400">
                Sign Up
              </Link>
            </Text>
          </VStack>
        </Box>
      </Box>
    </>
  );
}

export default Login;
