import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Box,
  Flex,
  HStack,
  VStack,
  Link,
  Text,
  Spacer,
  IconButton,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useColorMode,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  List,
  ListItem,
  ListIcon,
  useDisclosure,
  useBreakpointValue,
  Divider,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon, HamburgerIcon } from "@chakra-ui/icons";
import { FaHome, FaRegBuilding, FaCogs } from "react-icons/fa";

function DashboardNavbar() {
  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [organization, setOrganization] = useState("KemitStudio");

  const isSidebarVisible = useBreakpointValue({ base: false, lg: true }); // Sidebar visible only on large screens

  const handleLogout = () => {
    Cookies.remove("AuthToken");
    navigate("/login");
  };

  return (
    <>
      {/* Navbar */}
      <Box
        as="nav"
        bg={colorMode === "light" ? "white" : "gray.800"}
        borderBottomWidth={1}
        borderBottomColor={colorMode === "light" ? "gray.200" : "gray.700"}
        py={4}
        shadow="md"
        width="100%"
        zIndex={50}
        position="fixed"
      >
        <Flex maxW="1200px" mx="auto" align="center" px={6}>
          {/* Hamburger Icon for Sidebar (only visible on small screens) */}
          {!isSidebarVisible && (
            <IconButton
              icon={<HamburgerIcon />}
              variant="ghost"
              aria-label="Open menu"
              onClick={onOpen}
            />
          )}

          <Text fontWeight="bold" fontSize="2xl" ml={!isSidebarVisible ? 4 : 0}>
            Nexxy
          </Text>

          <Spacer />

          {/* Navbar Links */}
          <HStack spacing={8}>
            <Link
              as={RouterLink}
              to="/dashboard"
              fontWeight="bold"
              color={colorMode === "light" ? "gray.700" : "gray.300"}
              _hover={{ color: colorMode === "light" ? "black" : "white" }}
            >
              Dashboard
            </Link>
            <Link
              as={RouterLink}
              to="/apps"
              fontWeight="bold"
              color={colorMode === "light" ? "gray.700" : "gray.300"}
              _hover={{ color: colorMode === "light" ? "black" : "white" }}
            >
              Apps
            </Link>
            <Link
              as={RouterLink}
              to="/campaigns"
              fontWeight="bold"
              color={colorMode === "light" ? "gray.700" : "gray.300"}
              _hover={{ color: colorMode === "light" ? "black" : "white" }}
            >
              Campaigns
            </Link>
          </HStack>

          {/* Theme Toggle and User Actions */}
          <HStack spacing={4}>
            <IconButton
              onClick={toggleColorMode}
              aria-label="Toggle color mode"
              icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
              variant="ghost"
            />
            <Menu>
              <MenuButton as={Button} variant="link" cursor="pointer">
                <Avatar size="sm" name="User" bg="teal.500" />
              </MenuButton>
              <MenuList
                bg={colorMode === "light" ? "white" : "gray.700"}
                borderColor={colorMode === "light" ? "gray.200" : "gray.600"}
              >
                <MenuItem as={RouterLink} to="/profile">
                  Profile
                </MenuItem>
                <MenuItem as={RouterLink} to="/settings">
                  Settings
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={handleLogout} color="red.500">
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </Flex>
      </Box>

      {isSidebarVisible ? (
        <Box
          as="aside"
          bg={colorMode === "light" ? "gray.100" : "gray.900"}
          w="250px"
          h="100vh"
          position="fixed"
          top="0"
          left="0"
          zIndex={40}
          py={6}
          px={4}
          borderRightWidth="1px"
          borderRightColor={colorMode === "light" ? "gray.200" : "gray.700"}
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
        >
          {/* Organization Name / Logo */}
          <HStack mb={4} spacing={2}>
            {/* You can replace this Text or add an Icon here for a logo */}
            <Text fontSize="xl" fontWeight="bold" color="teal.500">
              {organization}
            </Text>
          </HStack>

          <Divider mb={4} />

          {/* Navigation Links */}
          <VStack align="start" spacing={4}>
            <List spacing={3} width="100%">
              <ListItem>
                <HStack>
                  <FaHome color="teal.500" />
                  <Link as={RouterLink} to="/dashboard" fontWeight="medium">
                    Dashboard
                  </Link>
                </HStack>
              </ListItem>
              <ListItem>
                <HStack>
                  <FaRegBuilding color="teal.500" />
                  <Link as={RouterLink} to="/apps" fontWeight="medium">
                    Apps
                  </Link>
                </HStack>
              </ListItem>
              <ListItem>
                <HStack>
                  <FaCogs color="teal.500" />
                  <Link as={RouterLink} to="/settings" fontWeight="medium">
                    Settings
                  </Link>
                </HStack>
              </ListItem>
            </List>
          </VStack>
        </Box>
      ) : (
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader fontSize="xl" fontWeight="bold" color="teal.500">
              {organization}
            </DrawerHeader>
            <DrawerBody>
              <VStack align="start" spacing={4}>
                <List spacing={3} width="100%">
                  <ListItem>
                    <HStack>
                      <FaHome color="teal.500" />
                      <Link as={RouterLink} to="/dashboard" fontWeight="medium">
                        Dashboard
                      </Link>
                    </HStack>
                  </ListItem>
                  <ListItem>
                    <HStack>
                      <FaRegBuilding color="teal.500" />
                      <Link as={RouterLink} to="/apps" fontWeight="medium">
                        Apps
                      </Link>
                    </HStack>
                  </ListItem>
                  <ListItem>
                    <HStack>
                      <FaCogs color="teal.500" />
                      <Link as={RouterLink} to="/settings" fontWeight="medium">
                        Settings
                      </Link>
                    </HStack>
                  </ListItem>
                </List>
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
}

export default DashboardNavbar;
