import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Image,
  VStack,
  SimpleGrid,
  Icon,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaBullseye, FaChartLine, FaUsers, FaCode } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaDiscord, FaGithub, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import Footer from "../template/footer";

const AdPlatformPage = () => {
  // Professional neutral color scheme
  const headingColor = useColorModeValue("gray.800", "gray.100");
  const sectionTextColor = useColorModeValue("gray.600", "gray.300");
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const boxBg = useColorModeValue("white", "gray.800");
  const iconColor = useColorModeValue("teal.600", "teal.300");

  const [budget, setBudget] = useState(100);
  const [monthlyUsers, setMonthlyUsers] = useState(50000);

  return (
    <>
      <Box
        bg={bgColor}
        color={headingColor}
        minH="100vh"
        px={{ base: 4, md: 10 }}
        py={{ base: 10, md: 20 }}
      >
        {/* Header Section */}
        <Box mt={{ base: 16, md: 32 }}>
          <VStack spacing={4} textAlign="center">
            <Heading
              as="h1"
              fontSize={{ base: "2xl", md: "4xl" }}
              fontWeight="bold"
              color={headingColor}
            >
              Maximize Your Reach with Nexxy AdNet
            </Heading>
            <Text
              fontSize={{ base: "md", md: "lg" }}
              color={sectionTextColor}
              maxW="600px"
              px={4}
            >
              Nexxy AdNet connects developers and advertisers seamlessly.
              Integrate high-quality ads into your app or drive brand awareness
              through our powerful, targeted ad platform.
            </Text>
            <Button
              as={Link}
              to="/early-access"
              colorScheme="teal"
              size="lg"
              fontWeight="bold"
              _hover={{ bg: "teal.700" }}
            >
              Early Access
            </Button>
          </VStack>
        </Box>

        {/* Image Section */}
        <Flex justify="center" mt={10}>
          <Image
            src="/adImage.png"
            alt="Ad Example"
            borderRadius="md"
            maxW={{ base: "100%", md: "70%", lg: "50%" }}
            height="auto"
            mx="auto"
            position="relative"
            zIndex={1}
            filter="none"
          />
        </Flex>

        {/* Benefits Section */}
        <Box mt={16} textAlign="center">
          <Heading
            as="h2"
            fontSize={{ base: "2xl", md: "3xl" }}
            fontWeight="bold"
            mb={6}
            color={headingColor}
          >
            Why Choose Nexxy AdNet?
          </Heading>
          <SimpleGrid
            columns={{ base: 1, sm: 2, md: 2, lg: 4 }}
            spacing={8}
            mt={8}
            px={{ base: 4, md: 8 }}
          >
            {[
              {
                icon: FaChartLine,
                title: "Boost Revenue",
                description:
                  "Monetize your app with competitive ad revenue, maximizing earnings per impression.",
              },
              {
                icon: FaBullseye,
                title: "Precise Targeting",
                description:
                  "Deliver ads tailored to your users' interests, boosting engagement and conversions.",
              },
              {
                icon: FaCode,
                title: "Developer Friendly",
                description:
                  "Easy SDK integration for iOS, Android, React Native, and Unity with minimal setup.",
              },
              {
                icon: FaUsers,
                title: "Engage Users",
                description:
                  "Non-intrusive ads that keep your users engaged without compromising their experience.",
              },
            ].map((item, idx) => (
              <Box
                key={idx}
                p={6}
                bg={boxBg}
                borderRadius="md"
                shadow="lg"
                textAlign="center"
              >
                <Icon as={item.icon} w={10} h={10} mb={4} color={iconColor} />
                <Text
                  fontSize="xl"
                  fontWeight="bold"
                  mb={2}
                  color={headingColor}
                >
                  {item.title}
                </Text>
                <Text
                  color={sectionTextColor}
                  fontSize={{ base: "sm", md: "md" }}
                >
                  {item.description}
                </Text>
              </Box>
            ))}
          </SimpleGrid>
        </Box>

        {/* How It Works Section */}
        <Flex
          flexDirection="column"
          alignItems="center"
          mt={20}
          px={{ base: 4, md: 0 }}
        >
          <Heading
            as="h2"
            fontSize={{ base: "2xl", md: "3xl" }}
            mb={4}
            textAlign="center"
            color={headingColor}
          >
            Simple Integration, Powerful Results
          </Heading>
          <Text
            fontSize={{ base: "md", md: "lg" }}
            color={sectionTextColor}
            textAlign="center"
            maxW="600px"
            mb={6}
          >
            Start earning revenue in minutes. With Nexxy AdNet, you can
            integrate our SDK with ease, track performance, and optimize
            results.
          </Text>
          <Box
            borderRadius="md"
            maxW={{ base: "100%", md: "70%", lg: "50%" }}
            mx="auto"
          >
            <Image
              src="/adImage2.png"
              alt="Ad Example"
              borderRadius="md"
              width="100%"
              height="auto"
              zIndex={1}
            />
          </Box>
        </Flex>

        {/* Tier Plans Section */}
        <Box mt={20} textAlign="center" px={{ base: 4, md: 8 }}>
          <Heading
            as="h2"
            fontSize={{ base: "2xl", md: "3xl" }}
            mb={8}
            color={headingColor}
          >
            Our Revenue Share Tiers
          </Heading>
          <Text
            fontSize={{ base: "md", md: "lg" }}
            color={sectionTextColor}
            maxW="600px"
            mx="auto"
            mb={10}
          >
            As your monthly earnings increase, so does your share of the
            revenue. Choose the plan that’s right for you and watch your profits
            grow.
          </Text>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} mb={8}>
            <Box
              p={6}
              bg={boxBg}
              borderRadius="md"
              shadow="lg"
              textAlign="center"
              border="1px solid"
              borderColor={useColorModeValue("gray.200", "gray.600")}
            >
              <Heading size="lg" mb={2} color={headingColor}>
                Tier 1
              </Heading>
              <Text fontSize="sm" color={sectionTextColor} mb={4}>
                Up to $10,000/month
              </Text>
              <Heading size="2xl" color="teal.500">
                70%
              </Heading>
              <Text fontSize="sm" color={sectionTextColor} mt={2}>
                Revenue Share
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBg}
              borderRadius="md"
              shadow="lg"
              textAlign="center"
              border="1px solid"
              borderColor={useColorModeValue("gray.200", "gray.600")}
            >
              <Heading size="lg" mb={2} color={headingColor}>
                Tier 2
              </Heading>
              <Text fontSize="sm" color={sectionTextColor} mb={4}>
                Between $10,000 and $30,000/month
              </Text>
              <Heading size="2xl" color="teal.500">
                80%
              </Heading>
              <Text fontSize="sm" color={sectionTextColor} mt={2}>
                Revenue Share
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBg}
              borderRadius="md"
              shadow="lg"
              textAlign="center"
              border="1px solid"
              borderColor={useColorModeValue("gray.200", "gray.600")}
            >
              <Heading size="lg" mb={2} color={headingColor}>
                Tier 3
              </Heading>
              <Text fontSize="sm" color={sectionTextColor} mb={4}>
                Above $30,000/month
              </Text>
              <Heading size="2xl" color="teal.500">
                Custom
              </Heading>
              <Text fontSize="sm" color={sectionTextColor} mt={2}>
                Revenue Share
              </Text>
            </Box>
          </SimpleGrid>

          <Text
            fontSize="md"
            color={sectionTextColor}
            mx="auto"
            fontStyle="italic"
          >
            Join our Early Access list now and enjoy an additional 5% revenue
            share for your first year.
          </Text>
        </Box>

        {/* Revenue Estimation Section */}
        <Box
          mt={16}
          p={{ base: 4, md: 8 }}
          bg={useColorModeValue("gray.100", "gray.800")}
          borderRadius="md"
          shadow="lg"
          textAlign="center"
          maxWidth="800px"
          mx="auto"
        >
          <Heading
            as="h2"
            fontSize={{ base: "2xl", md: "3xl" }}
            mb={4}
            color={useColorModeValue("gray.800", "gray.100")}
          >
            See How Much You Could Earn or Spend
          </Heading>
          <Text
            fontSize={{ base: "md", md: "lg" }}
            color={useColorModeValue("gray.600", "gray.300")}
            mb={6}
            px={4}
          >
            Adjust the slider to estimate your revenue or views based on your
            budget.
          </Text>

          <Tabs variant="soft-rounded" colorScheme="teal" isFitted>
            <TabList mb="4" px={{ base: 2, md: 0 }}>
              <Tab fontWeight="bold" fontSize={{ base: "sm", md: "md" }}>
                Revenue Estimation
              </Tab>
              <Tab fontWeight="bold" fontSize={{ base: "sm", md: "md" }}>
                Advertiser Views
              </Tab>
            </TabList>
            <TabPanels>
              {/* Revenue Estimation Panel */}
              <TabPanel>
                <Text
                  fontSize={{ base: "md", md: "lg" }}
                  color={useColorModeValue("gray.600", "gray.300")}
                  mb={6}
                  px={4}
                >
                  Adjust the slider to estimate your potential revenue based on
                  monthly active users.
                </Text>
                <Slider
                  defaultValue={50000}
                  min={1000}
                  max={500000}
                  step={1000}
                  onChange={(val) => setMonthlyUsers(val)}
                  mb={6}
                >
                  <SliderTrack bg={useColorModeValue("gray.200", "gray.600")}>
                    <SliderFilledTrack bg="teal.500" />
                  </SliderTrack>
                  <SliderThumb boxSize={6} bg="teal.500" />
                </Slider>
                <Text
                  fontSize={{ base: "md", md: "lg" }}
                  color={useColorModeValue("gray.800", "gray.100")}
                >
                  Monthly Active Users:{" "}
                  <strong>{monthlyUsers.toLocaleString()}</strong>
                </Text>
                <Box
                  mt={6}
                  p={4}
                  bg={useColorModeValue("gray.50", "gray.700")}
                  borderRadius="md"
                  shadow="md"
                >
                  <Text
                    fontSize={{ base: "md", md: "lg" }}
                    color={useColorModeValue("gray.600", "gray.300")}
                    mb={2}
                  >
                    Your Potential Annual Revenue
                  </Text>
                  <Heading size={{ base: "xl", md: "2xl" }} color="teal.500">
                    $
                    {Math.round(
                      (monthlyUsers / 1000) * 22 * 0.7
                    ).toLocaleString()}
                  </Heading>
                  <Text
                    fontSize="sm"
                    color={useColorModeValue("gray.500", "gray.400")}
                    mt={2}
                  >
                    Based on a $22 CPM rate with a 70% revenue share for
                    developers.
                  </Text>
                </Box>
              </TabPanel>

              {/* Advertiser Views Panel */}
              <TabPanel>
                <Text
                  fontSize={{ base: "md", md: "lg" }}
                  color={useColorModeValue("gray.600", "gray.300")}
                  mb={4}
                  px={4}
                >
                  Adjust the slider to estimate how many views you can get based
                  on your budget.
                </Text>
                <Slider
                  defaultValue={500}
                  min={10}
                  max={5000}
                  step={10}
                  onChange={(val) => setBudget(val)}
                  mb={6}
                >
                  <SliderTrack bg={useColorModeValue("gray.200", "gray.600")}>
                    <SliderFilledTrack bg="teal.400" />
                  </SliderTrack>
                  <SliderThumb boxSize={8} shadow="lg" bg="teal.500" />
                </Slider>
                <Text
                  fontSize={{ base: "md", md: "lg" }}
                  fontWeight="bold"
                  color={useColorModeValue("gray.800", "gray.100")}
                >
                  Budget:{" "}
                  <Box
                    as="span"
                    color={useColorModeValue("teal.800", "teal.100")}
                    px={2}
                    py={1}
                    bg={useColorModeValue("teal.100", "teal.800")}
                    borderRadius="md"
                  >
                    ${budget.toLocaleString()}
                  </Box>
                </Text>

                <Box
                  mt={8}
                  p={6}
                  bg={useColorModeValue("gray.50", "gray.700")}
                  borderRadius="lg"
                  shadow="lg"
                  border="1px solid"
                  borderColor={useColorModeValue("gray.200", "gray.600")}
                >
                  <Text
                    fontSize={{ base: "md", md: "lg" }}
                    color={useColorModeValue("gray.600", "gray.300")}
                    mb={2}
                  >
                    Estimated Views
                  </Text>
                  <Heading size={{ base: "xl", md: "2xl" }} color="teal.500">
                    {Math.round((budget / 20) * 1000).toLocaleString()} Views
                  </Heading>
                  <Text
                    fontSize="sm"
                    color={useColorModeValue("gray.500", "gray.400")}
                    mt={2}
                  >
                    Based on a CPM rate of $20 for premium placements.
                  </Text>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>

        {/* Contact Section */}
        <Box
          mt={20}
          textAlign="center"
          p={{ base: 4, md: 8 }}
          bg={boxBg}
          borderRadius="md"
        >
          <Heading
            as="h2"
            fontSize={{ base: "2xl", md: "3xl" }}
            mb={4}
            color={headingColor}
          >
            Let’s Grow Together
          </Heading>
          <Text
            color={sectionTextColor}
            fontSize={{ base: "md", md: "lg" }}
            mb={6}
            px={{ base: 2, md: 0 }}
          >
            Ready to make an impact? Connect with us to build your custom ad
            strategy with the Nexxy AdNet team.
          </Text>
          <Button
            as={Link}
            to="/contact"
            colorScheme="teal"
            size="lg"
            fontWeight="bold"
            _hover={{ bg: "teal.700" }}
          >
            Contact
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AdPlatformPage;
