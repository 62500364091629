// src/pages/CreateApp.jsx

import React, { useState } from "react";
import {
  Box,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Checkbox,
  CheckboxGroup,
  Select,
  Stack,
  useToast,
  useColorModeValue,
  Center,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

function CreateApp() {
  const [appName, setAppName] = useState("");
  const [platform, setPlatform] = useState("");
  const [appStoreUrl, setAppStoreUrl] = useState("");
  const [bundleId, setBundleId] = useState("");
  const [packageName, setPackageName] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [description, setDescription] = useState("");
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const { developerId } = useParams();

  const bgColor = useColorModeValue("white", "gray.800");
  const inputBgColor = useColorModeValue("gray.100", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = Cookies.get("AuthToken");

    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER}/v1/organization/${developerId}/apps/create`,
        {
          developerId,
          name: appName,
          platform,
          appStoreUrl,
          bundleId,
          packageName,
          websiteUrl,
          description,
          categories,
          tags,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast({
        title: "App created",
        description: "Your app has been created successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      navigate(`/organization/${developerId}/apps`);
    } catch (error) {
      toast({
        title: "Error creating app",
        description: error.response?.data?.message || "An error occurred.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Center minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <Box
        maxW="700px"
        w="full"
        mx="auto"
        p={6}
        borderWidth={1}
        borderRadius="md"
        boxShadow="lg"
        bg={bgColor}
        borderColor={borderColor}
        mt={100}
        mb={50}
      >
        <Heading as="h1" size="lg" textAlign="center" mb={8}>
          Create New App
        </Heading>
        <form onSubmit={handleSubmit}>
          <VStack spacing={6} align="stretch">
            <FormControl id="appName" isRequired>
              <FormLabel fontWeight="bold">App Name</FormLabel>
              <Input
                type="text"
                placeholder="Enter app name"
                bg={inputBgColor}
                value={appName}
                onChange={(e) => setAppName(e.target.value)}
              />
            </FormControl>

            <FormControl id="platform" isRequired>
              <FormLabel fontWeight="bold">Platform</FormLabel>
              <Select
                placeholder="Select platform"
                bg={inputBgColor}
                value={platform}
                onChange={(e) => setPlatform(e.target.value)}
              >
                <option value="iOS">iOS</option>
                <option value="Android">Android</option>
                <option value="React Native">React Native</option>
                <option value="Unity">Unity</option>
              </Select>
            </FormControl>

            <FormControl id="appStoreUrl">
              <FormLabel fontWeight="bold">App Store URL</FormLabel>
              <Input
                type="url"
                placeholder="https://appstore.com/yourapp"
                bg={inputBgColor}
                value={appStoreUrl}
                onChange={(e) => setAppStoreUrl(e.target.value)}
              />
            </FormControl>

            <Stack direction={{ base: "column", md: "row" }} spacing={4}>
              <FormControl id="bundleId">
                <FormLabel fontWeight="bold">Bundle ID (iOS)</FormLabel>
                <Input
                  type="text"
                  placeholder="com.yourapp.ios"
                  bg={inputBgColor}
                  value={bundleId}
                  onChange={(e) => setBundleId(e.target.value)}
                />
              </FormControl>
              <FormControl id="packageName">
                <FormLabel fontWeight="bold">Package Name (Android)</FormLabel>
                <Input
                  type="text"
                  placeholder="com.yourapp.android"
                  bg={inputBgColor}
                  value={packageName}
                  onChange={(e) => setPackageName(e.target.value)}
                />
              </FormControl>
            </Stack>

            <FormControl id="websiteUrl">
              <FormLabel fontWeight="bold">Website URL</FormLabel>
              <Input
                type="url"
                placeholder="https://yourapp.com"
                bg={inputBgColor}
                value={websiteUrl}
                onChange={(e) => setWebsiteUrl(e.target.value)}
              />
            </FormControl>

            <FormControl id="description">
              <FormLabel fontWeight="bold">Description</FormLabel>
              <Textarea
                placeholder="Enter a description of your app"
                bg={inputBgColor}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>

            <FormControl id="categories">
              <FormLabel fontWeight="bold">Categories</FormLabel>
              <CheckboxGroup
                colorScheme="teal"
                value={categories}
                onChange={(values) => setCategories(values)}
              >
                <Stack spacing={2} direction="row">
                  <Checkbox value="Social">Social</Checkbox>
                  <Checkbox value="Productivity">Productivity</Checkbox>
                  <Checkbox value="Games">Games</Checkbox>
                  <Checkbox value="Lifestyle">Lifestyle</Checkbox>
                </Stack>
              </CheckboxGroup>
            </FormControl>

            <FormControl id="tags">
              <FormLabel fontWeight="bold">Tags</FormLabel>
              <Input
                type="text"
                placeholder="Enter tags separated by commas"
                bg={inputBgColor}
                value={tags}
                onChange={(e) => setTags(e.target.value.split(","))}
              />
            </FormControl>

            <Button
              colorScheme="teal"
              type="submit"
              width="full"
              isLoading={loading}
              mt={6}
              size="lg"
            >
              Create App
            </Button>
          </VStack>
        </form>
      </Box>
    </Center>
  );
}

export default CreateApp;
