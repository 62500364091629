import React from "react";
import { Box, Heading, Text, VStack, Stack } from "@chakra-ui/react";

const TermsOfService = () => {
  return (
    <Box p={4} maxW="800px" mx="auto" mt={10}>
      <Heading as="h1" size="xl" mb={4} textAlign="center">
        Terms of Service
      </Heading>
      <VStack spacing={6} align="start">
        <Box>
          <Heading as="h2" size="md" mb={2}>
            1. Introduction
          </Heading>
          <Text>
            Welcome to Nexxy AdNet. By accessing or using our services, you
            agree to comply with these Terms of Service ("Terms"). These Terms
            govern your use of our advertising platform and related services
            ("Services"). If you do not agree, you may not use the Services.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            2. Eligibility and Age Restriction
          </Heading>
          <Text>
            You must be at least 18 years of age to access or use the Services.
            By using the Services, you represent and warrant that you meet this
            age requirement. Nexxy AdNet reserves the right to request proof of
            age at any time.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            3. Account Registration
          </Heading>
          <Text>
            To access certain features of the Services, you may be required to
            create an account. You agree to provide accurate, current, and
            complete information during registration and to keep your account
            credentials secure. You are solely responsible for all activity that
            occurs under your account.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            4. Payments and Fees
          </Heading>
          <Text>
            Payments for advertising services are processed through our payment
            partners, including Stripe. By using our platform, you agree to our
            posted pricing and payment terms. We reserve the right to change our
            fees at any time with reasonable prior notice. You are responsible
            for all charges associated with your use of the Services.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            5. Permitted and Prohibited Use
          </Heading>
          <Text>You agree not to engage in any activity that:</Text>
          <Text as="ul" pl={4}>
            <Text as="li">
              Violates any applicable law, regulation, or industry guideline.
            </Text>
            <Text as="li">
              Infringes upon the rights of others, including intellectual
              property rights.
            </Text>
            <Text as="li">
              Distributes spam, malware, or other harmful content.
            </Text>
            <Text as="li">
              Interferes with the proper functioning of the Services or any
              user’s enjoyment of them.
            </Text>
          </Text>
          <Text mt={2}>
            We reserve the right to investigate and take appropriate action
            against anyone who, in our sole discretion, violates this provision.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            6. Advertising Content and Approval
          </Heading>
          <Text>
            All advertising content submitted to Nexxy AdNet may be reviewed and
            is subject to approval. We reserve the right to reject or remove any
            advertisement that:
          </Text>
          <Text as="ul" pl={4}>
            <Text as="li">Is false, misleading, or deceptive.</Text>
            <Text as="li">
              Contains prohibited content, such as hate speech, adult content,
              or illegal products and services.
            </Text>
            <Text as="li">
              Violates applicable laws, regulations, or these Terms.
            </Text>
          </Text>
          <Text mt={2}>
            Advertisers are solely responsible for ensuring that their content
            complies with all applicable laws and regulations.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            7. Refunds and Cancellations
          </Heading>
          <Text>
            Refunds are offered only under specific circumstances and require
            our prior approval. Certain fees or services may be non-refundable,
            and these will be clearly disclosed at the time of purchase.
            Advertisers may cancel services by providing reasonable notice.
            Depending on the nature of the service, cancellation fees may apply.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            8. Early Access and Support Requests
          </Heading>
          <Text>
            By signing up for early access to Nexxy AdNet or submitting a
            support request, you grant us permission to contact you via email
            regarding product updates, new features, promotional offers, and
            support inquiries. You may opt out of marketing communications at
            any time, but certain transactional or service-related messages may
            still be sent.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            9. Termination
          </Heading>
          <Text>
            Nexxy AdNet may suspend or terminate your account and/or access to
            the Services at our sole discretion if we believe you have violated
            these Terms or if we deem it necessary to protect our interests or
            those of our users. Upon termination, your rights to use the
            Services will cease immediately.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            10. Disclaimer of Warranties
          </Heading>
          <Text>
            The Services are provided on an "as is" and "as available" basis.
            Nexxy AdNet expressly disclaims all warranties, express or implied,
            including, without limitation, implied warranties of
            merchantability, fitness for a particular purpose, and
            non-infringement. We do not guarantee that the Services will meet
            your requirements or be available uninterrupted, secure, or
            error-free.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            11. Limitation of Liability
          </Heading>
          <Text>
            To the fullest extent permitted by law, Nexxy AdNet, its affiliates,
            directors, employees, and agents shall not be liable for any
            indirect, incidental, special, consequential, or exemplary damages,
            including but not limited to lost profits, data, or goodwill,
            resulting from your use of the Services.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            12. Changes to These Terms
          </Heading>
          <Text>
            We may update these Terms from time to time. When we do, we will
            revise the "Last Updated" date at the bottom of this page. Your
            continued use of the Services after any such changes will constitute
            your acceptance of the updated Terms.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={2}>
            13. Contact Us
          </Heading>
          <Text>
            If you have questions or concerns about these Terms, please contact
            us at <a href="mailto:support@nexxyapp.com">support@nexxyapp.com</a>
            .
          </Text>
        </Box>
      </VStack>
    </Box>
  );
};

export default TermsOfService;
