import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack,
  Link,
  useColorModeValue,
  Heading,
} from "@chakra-ui/react";
import Footer from "../../template/footer";
function Signup() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [signupCode, setSignupCode] = useState("");

  // Redirect if already logged in
  useEffect(() => {
    const cookie = Cookies.get("AuthToken");
    if (cookie) {
      navigate("/");
    }
  }, [navigate]);

  // Signup Handler
  const signupHandler = () => {
    if (email && password && firstName && lastName && signupCode) {
      axios
        .post(`${process.env.REACT_APP_SERVER}/v1/auth/signup`, {
          email,
          password,
          name: `${firstName} ${lastName}`,
          signupCode,
        })
        .then((res) => {
          if (res.data.accessToken) {
            // Use accessToken instead of token
            Cookies.set("AuthToken", res.data.accessToken); // Save accessToken in cookies
            toast.success("Signup successful! Redirecting...");
            navigate("/dashboard");
          } else {
            toast.error(res.data.message || "Signup failed");
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.message || "Signup error");
        });
    } else {
      toast.error("Please fill out all fields.");
    }
  };

  return (
    <Box
      minH="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.800", "white")}
      p={6}
    >
      <Box
        maxW="md"
        p={6}
        borderWidth={1}
        borderRadius="md"
        boxShadow="lg"
        bg={useColorModeValue("white", "gray.700")}
      >
        <Heading as="h1" size="lg" textAlign="center" mb={6}>
          Sign Up
        </Heading>
        <VStack spacing={4} align="stretch">
          <FormControl id="firstName" isRequired>
            <FormLabel>First Name</FormLabel>
            <Input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="Enter your first name"
              bg={useColorModeValue("gray.100", "gray.600")}
              _placeholder={{
                color: useColorModeValue("gray.500", "gray.400"),
              }}
            />
          </FormControl>

          <FormControl id="lastName" isRequired>
            <FormLabel>Last Name</FormLabel>
            <Input
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Enter your last name"
              bg={useColorModeValue("gray.100", "gray.600")}
              _placeholder={{
                color: useColorModeValue("gray.500", "gray.400"),
              }}
            />
          </FormControl>

          <FormControl id="email" isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              bg={useColorModeValue("gray.100", "gray.600")}
              _placeholder={{
                color: useColorModeValue("gray.500", "gray.400"),
              }}
            />
          </FormControl>

          <FormControl id="password" isRequired>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              bg={useColorModeValue("gray.100", "gray.600")}
              _placeholder={{
                color: useColorModeValue("gray.500", "gray.400"),
              }}
            />
          </FormControl>

          <FormControl id="signupCode" isRequired>
            <FormLabel>Signup Code</FormLabel>
            <Input
              value={signupCode}
              onChange={(e) => setSignupCode(e.target.value)}
              placeholder="Enter your signup code"
              bg={useColorModeValue("gray.100", "gray.600")}
              _placeholder={{
                color: useColorModeValue("gray.500", "gray.400"),
              }}
            />
          </FormControl>

          <Button colorScheme="teal" onClick={signupHandler} width="full">
            Sign Up
          </Button>

          <Text textAlign="center">
            Already have an account?{" "}
            <Link as={RouterLink} to="/login" color="teal.400">
              Login
            </Link>
          </Text>

          <Text fontSize="sm" color="gray.500" textAlign="center" mt={4}>
            By continuing, you agree to our{" "}
            <Link as={RouterLink} to="/tos" color="teal.400">
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link as={RouterLink} to="/privacypolicy" color="teal.400">
              Privacy Policy
            </Link>
            .
          </Text>
        </VStack>
      </Box>
      <ToastContainer />
    </Box>
  );
}

export default Signup;
