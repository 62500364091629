import React, { useState } from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  useColorModeValue,
  useToast,
  Checkbox,
  Stack,
} from "@chakra-ui/react";
import axios from "axios";

function EarlyAccess() {
  const [email, setEmail] = useState("");
  const [isDev, setIsDev] = useState(false);
  const [isAdvertise, setIsAdvertise] = useState(false);
  const [userCount, setUserCount] = useState("");
  const toast = useToast();

  const bgColor = useColorModeValue("gray.50", "gray.900");
  const boxBg = useColorModeValue("white", "gray.800");
  const headingColor = useColorModeValue("teal.500", "teal.300");
  const subTextColor = useColorModeValue("gray.600", "gray.400");
  const labelColor = useColorModeValue("gray.700", "gray.300");
  const inputBg = useColorModeValue("gray.100", "gray.700");
  const inputBorderColor = useColorModeValue("gray.200", "gray.600");

  const handleRequestAccess = () => {
    if (!email) {
      toast({
        title: "Incomplete Information",
        description: "Please provide an email address.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!isDev && !isAdvertise) {
      toast({
        title: "Incomplete Information",
        description:
          "Please select at least one option (Advertise or Integrate SDK).",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (isDev && !userCount) {
      toast({
        title: "Incomplete Information",
        description: "Please specify how many users your app has.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const data = {
      email,
      advertise: isAdvertise,
      dev: isDev,
      userCount: isDev ? userCount : null,
    };

    axios
      .post(`${process.env.REACT_APP_SERVER}/v1/auth/early-access`, data)
      .then(() => {
        toast({
          title: "Request Submitted",
          description: "Thank you! We'll be in touch soon.",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        setEmail("");
        setUserCount("");
        setIsDev(false);
        setIsAdvertise(false);
      })
      .catch((err) => {
        const errorMessage =
          err.response?.data?.message || "Something went wrong.";
        if (
          errorMessage === "This email is already registered for early access."
        ) {
          toast({
            title: "Email Already Used",
            description: errorMessage,
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Error",
            description: errorMessage,
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        }
      });
  };

  return (
    <>
      <Box
        minH="100vh"
        bg={bgColor}
        display="flex"
        justifyContent="center"
        alignItems="center"
        py={{ base: 8, md: 16 }}
        px={{ base: 4, md: 6 }}
      >
        <Box
          maxW="lg"
          w="full"
          p={{ base: 6, md: 8 }}
          borderRadius="lg"
          boxShadow="2xl"
          bg={boxBg}
        >
          <VStack spacing={8} textAlign="center">
            <Heading
              size="xl"
              color={headingColor}
              fontSize={{ base: "2xl", md: "3xl" }}
            >
              Get Early Access
            </Heading>
            <Text
              fontSize={{ base: "sm", md: "md" }}
              color={subTextColor}
              maxW="400px"
              px={{ base: 2, md: 0 }}
            >
              Be among the first to experience our platform. Whether you're
              looking to advertise on our app network or integrate our SDK into
              your own app to start earning, enter your details below to request
              early access.
            </Text>

            <VStack spacing={4} width="full" align="stretch">
              <FormControl isRequired>
                <FormLabel
                  color={labelColor}
                  fontSize={{ base: "sm", md: "md" }}
                >
                  Your Email
                </FormLabel>
                <Input
                  type="email"
                  placeholder="e.g., john.doe@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  bg={inputBg}
                  borderColor={inputBorderColor}
                  _hover={{ borderColor: "teal.400" }}
                  _focus={{ borderColor: "teal.500", boxShadow: "outline" }}
                  fontSize={{ base: "sm", md: "md" }}
                  py={{ base: 2, md: 3 }}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel
                  color={labelColor}
                  fontWeight="bold"
                  fontSize={{ base: "sm", md: "md" }}
                >
                  How do you plan to use our platform?
                </FormLabel>
                <Stack spacing={3}>
                  <Checkbox
                    isChecked={isAdvertise}
                    onChange={(e) => setIsAdvertise(e.target.checked)}
                    colorScheme="teal"
                    fontSize={{ base: "sm", md: "md" }}
                  >
                    Advertise on our app network
                  </Checkbox>
                  <Checkbox
                    isChecked={isDev}
                    onChange={(e) => setIsDev(e.target.checked)}
                    colorScheme="teal"
                    fontSize={{ base: "sm", md: "md" }}
                  >
                    Integrate SDK into my own app
                  </Checkbox>
                </Stack>
              </FormControl>

              {isDev && (
                <FormControl isRequired>
                  <FormLabel
                    color={labelColor}
                    fontSize={{ base: "sm", md: "md" }}
                  >
                    How Many Users Does Your App Have?
                  </FormLabel>
                  <Input
                    type="number"
                    placeholder="Approximate number of users"
                    value={userCount}
                    onChange={(e) => setUserCount(e.target.value)}
                    bg={inputBg}
                    borderColor={inputBorderColor}
                    _hover={{ borderColor: "teal.400" }}
                    _focus={{ borderColor: "teal.500", boxShadow: "outline" }}
                    fontSize={{ base: "sm", md: "md" }}
                    py={{ base: 2, md: 3 }}
                  />
                </FormControl>
              )}
            </VStack>

            <Button
              colorScheme="teal"
              size="lg"
              width="full"
              onClick={handleRequestAccess}
              bg="teal.500"
              _hover={{ bg: "teal.400" }}
              _active={{ bg: "teal.600" }}
              fontSize={{ base: "sm", md: "md" }}
              py={{ base: 3, md: 4 }}
            >
              Request Access
            </Button>
          </VStack>
        </Box>
      </Box>
    </>
  );
}

export default EarlyAccess;
