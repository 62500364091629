import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  useColorModeValue,
  VStack,
  useToast,
} from "@chakra-ui/react";

const Contact = () => {
  const toast = useToast();
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const cardBg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "gray.200");
  const inputBg = useColorModeValue("gray.100", "gray.700");
  const borderColor = useColorModeValue("gray.300", "gray.600");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (!name || !email || !message) {
      toast({
        title: "Please fill out all required fields.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setIsSubmitting(true);
      // Replace this URL with your actual endpoint

      const payload = { name, email, message };
      await axios.post(
        `${process.env.REACT_APP_SERVER}/v1/support/support`,
        payload
      );

      toast({
        title: "Message sent!",
        description:
          "We have received your message and will get back to you shortly.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      // Clear the form after successful submission
      setName("");
      setEmail("");
      setMessage("");
    } catch (error) {
      console.error(error);
      toast({
        title: "Failed to send message",
        description: "Something went wrong. Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box bg={bgColor} minH="100vh" py={20} px={4}>
      <Box
        maxW="600px"
        mx="auto"
        p={10}
        borderRadius="md"
        bg={cardBg}
        boxShadow="lg"
      >
        <VStack spacing={8} align="stretch">
          <Box>
            <Heading as="h1" size="lg" color={textColor} mb={2}>
              Get in Touch
            </Heading>
            <Text fontSize="md" color={textColor}>
              Have questions, feedback, or want to learn more about our
              services? Fill out the form below and we’ll get back to you as
              soon as possible.
            </Text>
          </Box>

          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
            <FormControl isRequired>
              <FormLabel color={textColor}>Name</FormLabel>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="John Doe"
                focusBorderColor="teal.500"
                bg={inputBg}
                borderColor={borderColor}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel color={textColor}>Email</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="john@example.com"
                focusBorderColor="teal.500"
                bg={inputBg}
                borderColor={borderColor}
              />
            </FormControl>
          </SimpleGrid>

          <FormControl isRequired>
            <FormLabel color={textColor}>Message</FormLabel>
            <Textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Your message..."
              rows={6}
              focusBorderColor="teal.500"
              bg={inputBg}
              borderColor={borderColor}
            />
          </FormControl>

          <Button
            size="lg"
            colorScheme="teal"
            alignSelf="start"
            onClick={handleSubmit}
            isLoading={isSubmitting}
            loadingText="Sending..."
          >
            Send Message
          </Button>
        </VStack>
      </Box>
    </Box>
  );
};

export default Contact;
