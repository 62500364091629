import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack,
  Heading,
  Link,
  useToast,
  useColorMode,
  useColorModeValue,
  IconButton,
} from "@chakra-ui/react";
import { SunIcon, MoonIcon } from "@chakra-ui/icons";

function PasswordReset() {
  const [steps, setSteps] = useState("email");
  const [email, setEmail] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const [password, setPassWord] = useState("");
  const [repassword, setRePassword] = useState("");
  const toast = useToast();
  const { toggleColorMode } = useColorMode();
  const cardBg = useColorModeValue("white", "gray.800");
  const pageBg = useColorModeValue("gray.50", "gray.900");
  const textColor = useColorModeValue("gray.800", "white");
  const inputBg = useColorModeValue("gray.100", "gray.700");
  const inputPlaceholder = useColorModeValue("gray.500", "gray.400");

  const psw_reset_Handel = async () => {
    if (email) {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_SERVER}/user/password-reset`,
          { email }
        );
        if (res.data.Status) {
          setSteps("confirmation");
        } else {
          toast({
            title: "Error",
            description: res.data.Message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (err) {
        toast({
          title: "Server Error",
          description: "Please try again later.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Missing Information",
        description: "Please enter your email.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const verify_code_Handel = async () => {
    if (email && verifyCode) {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_SERVER}/user/verify-code`,
          { email, verifyCode }
        );
        if (res.data.Status) {
          setSteps("newpass");
        } else {
          toast({
            title: "Error",
            description: res.data.Message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (err) {
        toast({
          title: "Server Error",
          description: "Please try again later.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Missing Information",
        description: "Please fill in all fields.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const new_pass_Handel = async () => {
    if (email && verifyCode && password && password === repassword) {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_SERVER}/user/new-password`,
          { email, verifyCode, password }
        );
        if (res.data.Status) {
          setSteps("complite");
        } else {
          toast({
            title: "Error",
            description: res.data.Message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (err) {
        toast({
          title: "Server Error",
          description: "Please try again later.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Validation Error",
        description:
          password !== repassword
            ? "Passwords do not match."
            : "Please fill in all fields.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      minH="100vh"
      bg={pageBg}
      color={textColor}
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={4}
    >
      <IconButton
        icon={useColorModeValue(<MoonIcon />, <SunIcon />)}
        onClick={toggleColorMode}
        position="absolute"
        top={4}
        right={4}
        aria-label="Toggle Theme"
      />
      <Box
        bg={cardBg}
        p={8}
        borderRadius="md"
        boxShadow="lg"
        maxW="400px"
        w="100%"
        textAlign="center"
      >
        {steps === "email" && (
          <>
            <Heading as="h1" size="lg" mb={4}>
              Reset Password
            </Heading>
            <VStack spacing={4}>
              <FormControl id="email" isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  bg={inputBg}
                  _placeholder={{ color: inputPlaceholder }}
                />
              </FormControl>
              <Button
                colorScheme="teal"
                onClick={psw_reset_Handel}
                width="full"
              >
                Get New Password
              </Button>
            </VStack>
          </>
        )}

        {steps === "confirmation" && (
          <>
            <Heading as="h1" size="lg" mb={4}>
              Confirm Code
            </Heading>
            <VStack spacing={4}>
              <FormControl id="verifyCode" isRequired>
                <FormLabel>Confirmation Code</FormLabel>
                <Input
                  type="text"
                  value={verifyCode}
                  onChange={(e) => setVerifyCode(e.target.value)}
                  placeholder="Enter your code"
                  bg={inputBg}
                  _placeholder={{ color: inputPlaceholder }}
                />
              </FormControl>
              <Button
                colorScheme="teal"
                onClick={verify_code_Handel}
                width="full"
              >
                Confirm
              </Button>
            </VStack>
          </>
        )}

        {steps === "newpass" && (
          <>
            <Heading as="h1" size="lg" mb={4}>
              Set New Password
            </Heading>
            <VStack spacing={4}>
              <FormControl id="password" isRequired>
                <FormLabel>New Password</FormLabel>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassWord(e.target.value)}
                  placeholder="Enter new password"
                  bg={inputBg}
                  _placeholder={{ color: inputPlaceholder }}
                />
              </FormControl>
              <FormControl id="repassword" isRequired>
                <FormLabel>Confirm Password</FormLabel>
                <Input
                  type="password"
                  value={repassword}
                  onChange={(e) => setRePassword(e.target.value)}
                  placeholder="Confirm password"
                  bg={inputBg}
                  _placeholder={{ color: inputPlaceholder }}
                />
              </FormControl>
              <Button colorScheme="teal" onClick={new_pass_Handel} width="full">
                Submit
              </Button>
            </VStack>
          </>
        )}

        {steps === "complite" && (
          <>
            <Heading as="h1" size="lg" mb={4}>
              Success!
            </Heading>
            <Text mb={4}>Your password has been changed successfully.</Text>
            <Link as={RouterLink} to="/login" color="teal.400">
              Go to Login
            </Link>
          </>
        )}
      </Box>
    </Box>
  );
}

export default PasswordReset;
