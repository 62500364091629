import React, { useState } from "react";
import {
  Box,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Button,
  useToast,
  useColorModeValue,
  Center,
  Stack,
  Select,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

function CreateOrganization() {
  const [name, setName] = useState("");
  const [organizationType, setOrganizationType] = useState("");

  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const bgColor = useColorModeValue("white", "gray.800");
  const inputBgColor = useColorModeValue("gray.100", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = Cookies.get("AuthToken");

    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER}/v1/organization/create`,
        {
          name,
          type: organizationType,
          address: {
            street,
            city,
            state,
            postalCode,
            country,
          },
          contactInfo: {
            email: contactEmail,
            phone: contactPhone,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast({
        title: "Organization created",
        description: "Your organization has been created successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      navigate("/organizations");
    } catch (error) {
      toast({
        title: "Error creating organization",
        description: error.response?.data?.message || "An error occurred.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Center minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <Box
        maxW="600px"
        w="full"
        mx="auto"
        p={6}
        borderWidth={1}
        borderRadius="md"
        boxShadow="lg"
        bg={bgColor}
        borderColor={borderColor}
        mt={100}
        mb={50}
      >
        <Heading as="h1" size="lg" textAlign="center" mb={8}>
          Create New Organization
        </Heading>
        <form onSubmit={handleSubmit}>
          <VStack spacing={6} align="stretch">
            <FormControl id="name" isRequired>
              <FormLabel fontWeight="bold">Organization Name</FormLabel>
              <Input
                type="text"
                placeholder="Enter organization name"
                bg={inputBgColor}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>

            <FormControl id="organizationType" isRequired>
              <FormLabel fontWeight="bold">Organization Type</FormLabel>
              <Select
                placeholder="Select type"
                bg={inputBgColor}
                value={organizationType}
                onChange={(e) => setOrganizationType(e.target.value)}
              >
                <option value="Non-Profit">Non-Profit</option>
                <option value="Corporation">Corporation</option>
                <option value="Individual">Individual</option>
                <option value="LLC">LLC</option>
                <option value="Partnership">Partnership</option>
                <option value="Government">Government</option>
              </Select>
            </FormControl>

            <Box mt={4}>
              <Heading as="h2" size="md" mb={2}>
                Address
              </Heading>
              <FormControl id="street" isRequired>
                <FormLabel>Street</FormLabel>
                <Input
                  type="text"
                  placeholder="123 Main St"
                  bg={inputBgColor}
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </FormControl>
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={4}
                mt={4}
              >
                <FormControl id="city" isRequired>
                  <FormLabel>City</FormLabel>
                  <Input
                    type="text"
                    placeholder="City"
                    bg={inputBgColor}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </FormControl>
                <FormControl id="state" isRequired>
                  <FormLabel>State</FormLabel>
                  <Input
                    type="text"
                    placeholder="State"
                    bg={inputBgColor}
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                </FormControl>
              </Stack>
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={4}
                mt={4}
              >
                <FormControl id="postalCode" isRequired>
                  <FormLabel>Postal Code</FormLabel>
                  <Input
                    type="text"
                    placeholder="Postal Code"
                    bg={inputBgColor}
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                  />
                </FormControl>
                <FormControl id="country" isRequired>
                  <FormLabel>Country</FormLabel>
                  <Input
                    type="text"
                    placeholder="Country"
                    bg={inputBgColor}
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </FormControl>
              </Stack>
            </Box>

            <Box mt={4}>
              <Heading as="h2" size="md" mb={2}>
                Contact Information
              </Heading>
              <FormControl id="contactEmail" isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="contact@example.com"
                  bg={inputBgColor}
                  value={contactEmail}
                  onChange={(e) => setContactEmail(e.target.value)}
                />
              </FormControl>
              <FormControl id="contactPhone" mt={4}>
                <FormLabel>Phone</FormLabel>
                <Input
                  type="tel"
                  placeholder="+1234567890"
                  bg={inputBgColor}
                  value={contactPhone}
                  onChange={(e) => setContactPhone(e.target.value)}
                />
              </FormControl>
            </Box>

            <Button
              colorScheme="teal"
              type="submit"
              width="full"
              isLoading={loading}
              mt={6}
              size="lg"
            >
              Create Organization
            </Button>
          </VStack>
        </form>
      </Box>
    </Center>
  );
}

export default CreateOrganization;
