// src/pages/AppDashboard.jsx

import React, { useEffect, useState } from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  useColorModeValue,
  Center,
  Spinner,
  Divider,
  Link,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

function AppDashboard() {
  const { appId } = useParams();
  const [appData, setAppData] = useState(null);
  const [loading, setLoading] = useState(true);

  const bgColor = useColorModeValue("white", "gray.800");
  const cardColor = useColorModeValue("gray.50", "gray.700");
  const textColor = useColorModeValue("gray.700", "gray.100");
  const sectionColor = useColorModeValue("gray.600", "gray.300");

  useEffect(() => {
    const fetchAppData = async () => {
      try {
        const token = Cookies.get("AuthToken");
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER}/v1/app/${appId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAppData(response.data.app);
      } catch (error) {
        console.error("Error fetching app data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAppData();
  }, [appId]);

  if (loading) {
    return (
      <Center height="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (!appData) {
    return (
      <Center height="100vh">
        <Text color="red.500">App not found</Text>
      </Center>
    );
  }

  const {
    name,
    platform,
    appStoreUrl,
    bundleId,
    packageName,
    websiteUrl,
    description,
    categories,
    tags,
    status,
    createdAt,
    updatedAt,
    developer,
  } = appData;

  return (
    <Box maxW="800px" mx="auto" bg={bgColor} borderRadius="lg" shadow="lg">
      {/* Header Section */}
      <VStack spacing={3} align="start" mb={8} mt={50}>
        <Heading as="h1" size="xl" color={textColor} mb={1}>
          {name}
        </Heading>
        <Text color={sectionColor} fontSize="lg">
          Platform:{" "}
          <Text as="span" fontWeight="semibold">
            {platform}
          </Text>
        </Text>
        <Text color={sectionColor} fontSize="lg">
          Status:{" "}
          <Text as="span" fontWeight="semibold">
            {status || "Pending"}
          </Text>
        </Text>
        <Text color={sectionColor} fontSize="sm">
          Created: {new Date(createdAt).toLocaleDateString()}
        </Text>
        <Text color={sectionColor} fontSize="sm">
          Last Updated: {new Date(updatedAt).toLocaleDateString()}
        </Text>
      </VStack>

      <Divider my={6} />

      {/* App Details Section */}
      <Box bg={cardColor} p={5} borderRadius="md" shadow="sm" mb={8}>
        <Heading as="h3" size="md" color={textColor} mb={3}>
          App Details
        </Heading>
        <Text color={sectionColor} fontSize="md" mb={2}>
          <Text as="span" fontWeight="bold">
            Description:
          </Text>{" "}
          {description || "N/A"}
        </Text>
        <Text color={sectionColor} fontSize="md" mb={2}>
          <Text as="span" fontWeight="bold">
            Categories:
          </Text>{" "}
          {categories && categories.length > 0 ? categories.join(", ") : "N/A"}
        </Text>
        <Text color={sectionColor} fontSize="md" mb={2}>
          <Text as="span" fontWeight="bold">
            Tags:
          </Text>{" "}
          {tags && tags.length > 0 ? tags.join(", ") : "N/A"}
        </Text>
        <Text color={sectionColor} fontSize="md" mb={2}>
          <Text as="span" fontWeight="bold">
            Bundle ID:
          </Text>{" "}
          {bundleId || "N/A"}
        </Text>
        <Text color={sectionColor} fontSize="md" mb={2}>
          <Text as="span" fontWeight="bold">
            Package Name:
          </Text>{" "}
          {packageName || "N/A"}
        </Text>
        <Text color={sectionColor} fontSize="md" mb={2}>
          <Text as="span" fontWeight="bold">
            App Store URL:
          </Text>{" "}
          {appStoreUrl ? (
            <Link href={appStoreUrl} color="teal.500" isExternal>
              {appStoreUrl}
            </Link>
          ) : (
            "N/A"
          )}
        </Text>
        <Text color={sectionColor} fontSize="md">
          <Text as="span" fontWeight="bold">
            Website:
          </Text>{" "}
          {websiteUrl ? (
            <Link href={websiteUrl} color="teal.500" isExternal>
              {websiteUrl}
            </Link>
          ) : (
            "N/A"
          )}
        </Text>
      </Box>

      <Divider my={6} />

      {/* Developer & Organization Information */}
      {developer && developer.organization ? (
        <Box bg={cardColor} p={5} borderRadius="md" shadow="sm">
          <Heading as="h3" size="md" color={textColor} mb={3}>
            Developer & Organization Information
          </Heading>
          <Text color={sectionColor} fontSize="md" mb={2}>
            <Text as="span" fontWeight="bold">
              Developer ID:
            </Text>{" "}
            {developer.devId}
          </Text>
          <Text color={sectionColor} fontSize="md" mb={2}>
            <Text as="span" fontWeight="bold">
              Organization Name:
            </Text>{" "}
            {developer.organization.name}
          </Text>
          <Text color={sectionColor} fontSize="md" mb={2}>
            <Text as="span" fontWeight="bold">
              Organization Type:
            </Text>{" "}
            {developer.organization.type}
          </Text>
          <Text color={sectionColor} fontSize="md" mb={2}>
            <Text as="span" fontWeight="bold">
              Contact Email:
            </Text>{" "}
            {developer.organization.contactInfo.email}
          </Text>
          <Text color={sectionColor} fontSize="md">
            <Text as="span" fontWeight="bold">
              Contact Phone:
            </Text>{" "}
            {developer.organization.contactInfo.phone || "N/A"}
          </Text>
        </Box>
      ) : (
        <Text color={sectionColor} fontSize="md" mt={6}>
          No developer or organization information available.
        </Text>
      )}
    </Box>
  );
}

export default AppDashboard;
